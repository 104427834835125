export default [
  {
    id: 1,
    name: 'A',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 2,
    name: 'B',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 3,
    name: 'C',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 4,
    name: 'D',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 5,
    name: 'E',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 6,
    name: 'F',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 7,
    name: 'G',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 8,
    name: 'H',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 9,
    name: 'I',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 10,
    name: 'J',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 11,
    name: 'K',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 12,
    name: 'L',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 13,
    name: 'M',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 14,
    name: 'N',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 15,
    name: 'O',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 16,
    name: 'P',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 17,
    name: 'Q',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 18,
    name: 'R',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 19,
    name: 'S',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 20,
    name: 'T',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 21,
    name: 'U',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 22,
    name: 'V',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 23,
    name: 'W',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 24,
    name: 'X',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 25,
    name: 'Y',
    isUsed: false,
    isHovered: false,
  },
  {
    id: 26,
    name: 'Z',
    isUsed: false,
    isHovered: false,
  },
]
